<template>
  <div v-if="pageInfo.total" class="pagination">
    <el-pagination
      :current-page.sync="currentPageInfo.page"
      :page-sizes="[9, 45, 90, 450, 900]"
      :page-size="currentPageInfo.limit"
      :total="pageInfo.total"
      background
      layout="prev, pager, next"
      @size-change="configUpperLevel"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
    <span class="el-pagi-right el-pagination__total"
      >{{ $t("el.pagination.totalname") }}{{ currentPageInfo.pages
      }}{{ $t("el.pagination.pages") }} {{ currentPageInfo.total
      }}{{ $t("el.pagination.items") }}
    </span>
  </div>
</template>
<script>
export default {
  props: {
    listQuery: {
      type: Object,
      required: true,
    },
    pageInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentPageInfo: {
        limit: 9,
        page: 1,
      },
    };
  },
  methods: {
    created() {},
    /**
     * 获取分页动态参数
     */
    getPageInfo() {
      let sendData = Object.assign({}, this.currentPageInfo);
      return sendData;
    },
    configUpperLevel(size) {
      this.currentPageInfo.limit = size;
      this.$emit("configHandle", this.getPageInfo());
    },
    handleCurrentChange(val) {
      this.currentPageInfo.page = val;
      this.$emit("configHandle", this.getPageInfo());
    },
    resetPage() {
      this.currentPageInfo.page = 1;
    },
  },
};
</script>
<style lang="less" scoped>
.pagination {
  margin-top: 10px;
  font-size: 13px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
}
</style>

<style lang="less" scoped>
.pagination {
  display: flex;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
  .el-pagi-right {
  }
}
</style>
