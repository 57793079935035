<template>
  <div class="home">
    <div class="content">
      <el-button
        v-if="$route.name === 'myCourses'"
        type="primary"
        size="small"
        class="teachManage-tool-btnicon goback-btn"
        @click="gobackHome"
      >
        <i class="icon-navtop-back"></i>{{ $t("el.common.back") }}</el-button
      >
      <div
        v-if="!noAuth"
        ref="guideHeader"
        class="header"
        :disable="guideVisible"
        :style="guideVisible ? 'pointer-events: none;z-index: 10000' : ''"
        :class="[!showAll ? 'show-all-choose' : '']"
      >
        <div class="header-title">{{ $t("el.courseList.myCourses") }}</div>
        <div class="f-l">
          <el-input
            :placeholder="$t('el.courseList.enterCourseName')"
            size="small"
            :debounce="500"
            v-model="chooseParams.courseName"
            style="width: 200px; margin-left: 24px; margin-right: 24px"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getCourseList"
            ></el-button
          ></el-input>
        </div>
        <div class="quick" v-show="!showAll">
          <div class="f-l">
            <el-select
              v-model="chooseParams.gradeId"
              :placeholder="$t('el.common.chooseGrade')"
              size="small"
              prefix-icon="el-icon-search"
              style="width: 140px; margin-right: 24px"
              clearable
            >
              <el-option
                v-for="item in gradeList"
                :key="item.id"
                :label="
                  item.id === '' ? $t('el.courseList.allGrade') : item.cnName
                "
                :value="item.id"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="chooseParams.subjectId"
              :placeholder="$t('el.common.chooseSubject')"
              size="small"
              prefix-icon="el-icon-search"
              style="width: 160px; margin-right: 24px"
              clearable
            >
              <el-option
                v-for="item in subjectList"
                :key="item.id"
                :label="
                  item.id === '' ? $t('el.courseList.allSubject') : item.cnName
                "
                :value="item.id"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="chooseParams.isTry"
              :placeholder="$t('el.common.pleaseSelect')"
              size="small"
              clearable
              style="width: 140px; margin-right: 10px"
            >
              <el-option
                v-for="item in courseTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="choose" v-show="!showAll">
          <!-- <el-button
            type="text"
            v-if="!$store.state.user.isHiddenSign"
            @click="showAuth"
            class="show-auth-btn"
            >{{ $t("el.courseList.application") }}</el-button
          > -->
          <div class="span more" @click="showMore">
            <img
              class="choose-img"
              src="@/assets/images/icon_index_open@2x.png"
              :alt="$t('el.common.centerName')"
              :title="$t('el.common.centerName')"
            />
            <span>{{ $t("el.courseList.moreFilters") }}</span>
          </div>
        </div>
      </div>
      <div class="choose-all" v-show="showAll && !noAuth">
        <div class="choose-block">
          <el-form
            :model="chooseParams"
            label-width="90px"
            label-position="left"
          >
            <el-form-item :label="$t('el.courseList.grade')">
              <el-radio-group v-model="chooseParams.gradeId">
                <el-radio
                  v-for="item in gradeList"
                  :key="item.id"
                  :label="item.id"
                  class="choose-radio"
                  >{{
                    item.id == "" ? $t("el.courseList.all") : item.cnName
                  }}</el-radio
                >
              </el-radio-group>
            </el-form-item>
            <el-form-item :label="$t('el.courseList.subject')">
              <el-radio-group v-model="chooseParams.subjectId">
                <el-radio
                  v-for="item in subjectList"
                  :key="item.id"
                  :label="item.id"
                  class="choose-radio"
                  >{{
                    item.id == "" ? $t("el.courseList.all") : item.cnName
                  }}</el-radio
                >
              </el-radio-group>
            </el-form-item>
            <el-form-item :label="$t('el.courseList.semester')">
              <el-radio-group v-model="chooseParams.termId">
                <el-radio
                  v-for="item in semesterList"
                  :key="item.id"
                  :label="item.id"
                  class="choose-radio"
                  >{{
                    item.id == "" ? $t("el.courseList.all") : item.cnName
                  }}</el-radio
                >
              </el-radio-group>
            </el-form-item>
          </el-form>
          <!-- <div
            class="all-item"
            v-for="(item, index) in selectList"
            :key="index"
            :class="[index != selectList.length - 1] ? 'all-item-mb' : ''"
          >
            <div
              v-if="
                item.cnName != '学期' &&
                  (item.cnName != '科目' ||
                    (item.cname == '科目' && item.children.length > 1))
              "
            >
              <span class="item-bt">{{ item.cnName }}：</span>
              <span
                class="item"
                @click="chooseSub(0, lable, i, item, index)"
                :class="[
                  item.chooseIndex == 0 && i == 0
                    ? 'i-first'
                    : item.chooseIndex == i
                    ? 'i-active'
                    : 'i-normal',
                ]"
                v-for="(lable, i) in item.children"
                :key="i"
                >{{ lable.cnName }}</span
              >
            </div>
            <div
              v-else-if="item.cnName == '科目' && item.children.length <= 1"
            ></div>
            <div v-else>
              <span class="item-bt">{{ item.cnName }}：</span>
              <span
                class="item"
                v-for="(lable, i) in item.children"
                :class="[
                  termStatus && termStatus == lable.id
                    ? 'i-active'
                    : item.chooseIndex == 0 && i == 0
                    ? 'i-first'
                    : 'i-normal',
                ]"
                :key="i"
                @click="chooseSub(1, lable, i, item, index)"
                >{{ lable.cnName }}</span
              >
            </div>
          </div> -->
          <!-- <div class="all-item all-item-mb">
            <span class="item-bt">{{ $t('el.schoolResourceManage.grade') }}{{ $t('el.symbol.colon') }}</span>
            <span
              class="item"
              @click="chooseC(item , index)"
              :class="[chooseClass == 0 && index==0 ? 'i-first': chooseClass == index ? 'i-active' :  'i-normal']"
              v-for="(item, index) in classOption"
              :key="index"
            >{{item.label}}</span>
          </div>
          <div class="all-item">
            <span class="item-bt">学期：</span>
            <span
              class="item"
              @click="chooseT(item , index)"
              :class="[chooseTeam == 0 && index==0 ? 'i-first': chooseTeam == index ? 'i-active' :  'i-normal']"
              v-for="(item, index) in teamOption"
              :key="index"
            >{{item.label}}</span>
          </div>-->
        </div>
        <div class="select-more">
          <div class="span more" @click="showMore">
            <img
              class="choose-img"
              src="@/assets/images/icon_index_open@2x.png"
              :alt="$t('el.common.centerName')"
              :title="$t('el.common.centerName')"
            />
            <span>{{ $t("el.courseList.lessFilters") }}</span>
          </div>
        </div>
      </div>
      <div v-loading="loading">
        <div class="section" v-if="!noAuth && courseList.length > 0">
          <el-row :gutter="20">
            <el-col :span="8" v-for="(item, index) in courseList" :key="index">
              <course-block
                :courseDetail="item"
                :ifMylist="true"
                :fromPage="'myCourses'"
              ></course-block>
            </el-col>
          </el-row>
        </div>
        <div
          class="no-data"
          v-if="!noAuth && !courseList.length"
          :class="[showAll ? 'data-p83' : 'data-p42']"
        >
          <img
            class="no-img"
            src="@/assets/images/subjectType/pic_empty_course@2x.png"
            :alt="$t('el.common.centerName')"
            :title="$t('el.common.centerName')"
          />
          <div>
            {{
              hasFilter
                ? $t("el.courseList.noCourses")
                : $t("el.common.NoCourse")
            }}
          </div>
        </div>
      </div>
      <div class="no-auth-block" v-if="noAuth">
        <div class="no-auth">
          <div class="head">
            {{ $t("el.authDialog.applicationTitle") }}
          </div>
          <div class="auth-data">
            <el-table
              v-loading="loading"
              ref="multipleTable"
              :data="authList"
              border
              tooltip-effect="dark"
              style="width: 100%"
              align="center"
              :empty-text="$t('el.common.noData')"
            >
              <el-table-column
                prop="staffName"
                :label="$t('el.authDialog.name')"
                show-overflow-tooltip
              ></el-table-column>
              <!-- <el-table-column
                :label="$t('el.authDialog.subjects')"
                show-overflow-tooltip
                width="155"
              >
                <template slot-scope="scope">
                  <span
                    v-for="(item, index) in scope.row.subjectAuthList"
                    :key="index"
                  >
                    {{ item.subjectName }}
                    <span v-if="index != scope.row.subjectAuthList.length - 1"
                      >、</span
                    >
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('el.authDialog.grades')"
                show-overflow-tooltip
                width="280"
              >
                <template slot-scope="scope">
                  <span
                    v-for="(item, index) in scope.row.gradeAuthList"
                    :key="index"
                  >
                    {{ item.gradeName }}
                    <span v-if="index != scope.row.gradeAuthList.length - 1"
                      >、</span
                    >
                  </span>
                </template>
              </el-table-column> -->
              <el-table-column :label="$t('el.authDialog.courseNames')">
                <template slot-scope="scope">
                  <tooltip-over
                    :content="
                      scope.row.authCourseNames &&
                      scope.row.authCourseNames.join('、')
                    "
                    :singleAble="true"
                  ></tooltip-over>
                  <!-- <span
                      v-for="(item, index) in scope.row.authCourseNames"
                      :key="index"
                    >
                      {{ item }}
                      <span v-if="index != scope.row.authCourseNames.length - 1"
                        >、</span
                      >
                    </span> -->
                </template>
              </el-table-column>
              <el-table-column
                prop="mobile"
                :label="$t('el.authDialog.telephone')"
                show-overflow-tooltip
                width="150"
              ></el-table-column>
            </el-table>
          </div>
        </div>
        <div class="pagination" v-if="authList && authList.length && noAuth">
          <xm-pagination
            :total="pageInfoAuth.total"
            :params="pagingParamsAuth"
            @params-change="(params) => (pagingParamsAuth = params)"
          />
        </div>
      </div>
      <el-dialog
        width="0px"
        :show-close="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        center
        :lock-scroll="true"
        :visible.sync="guideVisible"
        custom-class="guide-dialog"
        :style="'top:' + guideHeight + 'px;height: 300px'"
      >
        <div class="dialog-content t-c">
          <el-image
            style="width: 408px; height: 132px"
            :src="iconKnow"
          ></el-image>
          <el-image
            style="
              width: 60px;
              height: 60px;
              margin-top: -20px;
              cursor: pointer;
            "
            :src="iconClosed"
            @click="changeGuideDialog(false)"
          ></el-image>
        </div>
        <!-- <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="guideVisible = false"
          >我知道了</el-button
        >
      </div> -->
      </el-dialog>
    </div>
    <div class="pagination" v-if="courseList && courseList.length && !noAuth">
      <xm-pagination
        :total="pageInfo.total"
        :params="pagingParams"
        @params-change="(params) => (pagingParams = params)"
      />
    </div>

    <AuthDialog v-if="dialogAuth" ref="authConfig"></AuthDialog>
    <!--  -->
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import PublicPagination from "./components/publicPagination";
import AuthDialog from "./authDialog";
import InstallTip from "@/components/global/install-tip";
import tooltipOver from "@/components/biz-components/tooltipOver/index.vue";
import courseBlock from "@/views/components/course-block.vue";
import screenfull from "screenfull";
import {
  getQueryMyCoursePage,
  getQueryKnowledg,
  getQueryUserAuthInfo,
  selectUserAuth,
} from "@/api/home";
// import { getQueryMyCoursePage } from "@/api/courseList";
var that;

export default {
  name: "myCourses",
  components: {
    // InstallTip,
    // PublicPagination,
    AuthDialog,
    tooltipOver,
    courseBlock,
  },
  props: {
    guideVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      noAuth: false,
      iconKnow: require("@/assets/images/icon/icon-know-home.png"),
      iconClosed: require("@/assets/images/icon/icon-closed.png"),
      dialogAuth: false,
      isSwitch: false,
      guideHeight: -100,
      chooseItem: 0,
      chooseSubject: 0,
      chooseClass: 0,
      chooseTeam: 0,
      loading: true,
      courseList: [],
      showAll: false,
      authList: [],
      pageInfo: {
        total: 0,
      },
      pageInfoAuth: {
        total: 0,
      },
      pagingParamsAuth: {
        limit: 5,
        offset: 0,
        page: 1,
        pages: 1,
      },
      // semesterList: [],
      gradeList: [],
      subjectList: [],
      selectList: [],
      chooseParams: {
        courseName: "", // 模糊关键字
        subjectId: "", // 科目
        gradeId: "", // 年级
        versionId: "", // 版本
        termId: "", // 学期
        isTry: "", // 课程类型选择值 字段
      },
      pagingParams: {
        limit: 9,
        offset: 0,
        page: 1,
        pages: 1,
      },
      termStatus: undefined, // 当前学期筛选条件
    };
  },
  // 侦听器
  watch: {
    /**
     * 分页参数改变重新获取数据
     */
    // chooseParams() {
    //   this.getCourseList();
    // },
    chooseParams: {
      // 深度监听
      handler(val, oldVal) {
        this.pagingParams = {
          limit: 9,
          offset: 0,
          page: 1,
          pages: 1,
        };
        // this.getCourseList();
      },
      deep: true,
    },
    pagingParams() {
      this.getCourseList();
    },
    /**
     * 分页参数改变重新获取数据
     */
    // pagingParamsAuth() {
    //   this.getAuthData();
    // },
    /**
     * 查询参数改变重新获取数据
     */
    listQuery() {
      // 如果分页数大于1，就改变页数触发，分页监听查询。
      if (this.pagingParams.page > 1) {
        this.$set(this.pagingParams, "page", 1);
        return;
      }
      this.getCourseList();
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      saasDictData: (state) => state.dict.saasDictData,
      // 学期下拉数据
      semesterList(state) {
        return [
          {
            cnName: "全部",
            id: "",
          },
          ...state.dict.courseTermId.map((item) => ({
            cnName: item.dictionaryText,
            id: item.dictionaryKey,
          })),
        ];
      },
    }),
    courseTypeList() {
      return [
        {
          value: "",
          label: this.$t("el.courseList.allCourse"),
        },
        {
          value: 0,
          label: this.$t("el.courseList.formalCourses"),
        },
        {
          value: 1,
          label: this.$t("el.courseList.trialCourses"),
        },
      ];
    },
    // 筛选条件是否为空
    hasFilter() {
      return Object.values(this.chooseParams).some((f) => !!f);
    },
  },
  filters: {},
  beforeCreate() {
    that = this;
  },
  created() {},
  mounted() {
    // this.getUserAuth();
    // this.init();
    this.$nextTick(() => {
      let top1 = this.$refs["guideHeader"].getBoundingClientRect().top;
      let top2 = this.$refs["guideHeader"].scrollTop;
      this.guideHeight = top1 + top2;
    });
  },
  methods: {
    async init() {
      Object.assign(this.$data, this.$options.data());
      if (this.$route.query.redirect) {
        this.$router.push(this.$route.query.redirect);
      } else {
        // this.getCourseList();
        this.getQueryCourseAuth();
      }
      // this.getUserAuth();
    },
    // 跳转我的课表
    toMyTimeTable() {
      this.$router.push({
        name: "myTimeTable",
      });
    },
    // 返回首页
    gobackHome() {
      this.$router.push({
        name: "home",
      });
    },
    goCourseDetail(id) {
      localStorage.setItem("detailsFromPage", "myCourses");
      this.$router.push({
        name: "CourseDetails",
        query: {
          id: id,
        },
      });
    },
    // getAuthData() {
    //   return getQueryUserAuthInfo(this.pagingParamsAuth)
    //     .then(({ data }) => {
    //       if (Array.isArray(data.records)) this.authList = data.records;
    //       this.pageInfoAuth.total = data.total || 0;
    //       this.pagingParamsAuth.pages = data.pages || 0;
    //       this.loading = false;
    //     })
    //     .catch(() => {});
    // },
    // getUserAuth() {
    //   let staffCode = this.$store.state.user.staffCode;
    //   return selectUserAuth(staffCode).then((res) => {
    //     if (
    //       res.data.staffAuth == null || (!res.data.staffAuth.authCourseIds || res.data.staffAuth.authCourseIds.length===0) ||
    //       res.data.canAccess === false
    //     ) {
    //       this.noAuth = true;
    //       this.getAuthData();
    //     } else {
    //       this.getCourseList();
    //     }
    //   });
    // },
    getAuthList() {
      this.dialogAuth = true;
      this.$nextTick(() => {
        this.$refs.authConfig && this.$refs.authConfig.init();
      });
    },
    getQueryCourseAuth() {
      if (Array.isArray(this.saasDictData)) {
        this.selectList = JSON.parse(JSON.stringify(this.saasDictData));
        this.selectList.forEach((item) => {
          item.children.unshift({
            cnName: "全部",
            id: "",
          });
          item.chooseIndex = 0;
          // if (item.code === "termId") {
          //   this.semesterList = item.children;
          // }
          if (item.code === "gradeId") {
            this.gradeList = item.children;
          }
          if (item.code === "subjectId") {
            this.subjectList = item.children;
          }
        });
        this.selectList = this.selectList.filter((item) => {
          return item.cnName !== "教材版本";
        });
      }
    },
    openNoAuth() {
      // this.noAuth = true
    },
    chooseclass() {
      this.$nextTick(() => {
        this.$refs.selectCourse && this.$refs.selectCourse.init();
      });
    },
    changeGuideDialog(isShow) {
      this.$emit("changeGuideDialog", isShow);
    },
    showAuth() {
      this.getAuthList();
    },
    showMore() {
      this.showAll = !this.showAll;
    },
    choseItem(type, termId) {
      this.termStatus = termId;
      this.chooseItem = type;
      this.pagingParams.page = 1;
      this.pagingParams.offset = 0;
      this.chooseParams.termId = termId;
      this.selectList.forEach((item) => {
        if (item.cnName === "学期") {
          item.chooseIndex = type;
        }
      });
      this.getCourseList();
    },
    /**
     * 获取参数
     */
    getQueryParams() {
      return Object.assign(
        {},
        this.listQuery,
        this.pagingParams,
        this.chooseParams
      );
    },
    getCourseList(query) {
      this.loading = true;
      var params = this.getQueryParams();
      if (params.offset > this.pageInfo.total) return;
      this.nowQueryParmas = Object.assign({}, params, query);
      return getQueryMyCoursePage(this.nowQueryParmas)
        .then(({ data }) => {
          if (Array.isArray(data.records)) this.courseList = data.records;
          this.pageInfo.total = data.total || 0;
          this.pagingParams.pages = data.pages || 0;

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  // beforeRouteEnter(to, from, next) {
  //   // ...

  //   // 用来判断是否缓存
  //   if (from.name !== "CourseDetails" && from.name !== "hdCourseDetails") {
  //     to.meta.isBack = false;
  //   } else {
  //     to.meta.isBack = true;
  //   }

  //   next();
  // },
  activated() {
    // 如果是课程详情页返回 则只刷新列表数据
    if (this.$route.meta.isBack) {
      this.$nextTick(() => {
        this.getCourseList();
      });
    } else {
      // 否则 重新请求所有数据
      this.init();
    }
  },
};
</script>

<style lang="less" scoped>
/deep/.el-pagination.is-background .btn-next,
/deep/.el-pagination.is-background .btn-prev,
/deep/.el-pagination.is-background .el-pager li {
  background-color: #f6f8fc;
}
/deep/ .el-dialog__headerbtn:focus .el-dialog__close,
/deep/ .el-dialog__headerbtn:hover .el-dialog__close {
  color: @themeBlue;
}
/deep/ .el-input-group__append,
.el-input-group__prepend {
  padding: 0 10px;
  // color:#999;
  background-color: #f4f5f8;
  border: none;
}
/deep/ .f-l .el-input__inner {
  background-color: #f4f5f8;
  color: #333;
  border: none;
}
/deep/ .f-l .el-input__inner::placeholder {
  color: #666;
}
/deep/ .f-l .el-input__prefix,
.el-input__suffix {
  color: #666;
}
.choose-block {
  /deep/ .el-form-item__label {
    line-height: 28px;
    color: #131313;
    font-weight: bold;
  }
  /deep/ .el-form-item {
    margin-bottom: 10px !important;
  }
  .choose-radio {
    border: 1px solid rgba(0, 0, 0, 0);
    padding: 4px 10px;
    margin-bottom: 10px;
    /deep/ .el-radio__input {
      display: none;
    }
    /deep/ .el-radio__label {
      padding-left: 0;
    }
  }
  /deep/ .is-checked {
    border-radius: 20px;
    border: 1px solid #6049ff;
  }
  /deep/ .el-radio__input.is-checked + .el-radio__label {
    color: #6049ff;
  }
}
.goback-btn {
  border-radius: 20px;
}
.show-auth-btn {
  margin-right: 10px;
}
// 图标
.icon-navtop-back {
  width: 12px;
  height: 12px;
  margin-right: 6px;
  color: #fff;
  .gen-icon(white_navtop_back);
}
.home {
  .dialog-content {
    position: absolute;
    // background: #ffffff;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
  }
  .content {
    padding-top: 32px;
    .no-auth-block {
      background-color: #fff;
      margin-top: 14px;
      padding-top: 20px;
      border-radius: 6px;
    }
    .header {
      position: relative;
      background-color: #fff;
      height: 34px;
      border-radius: 6px;
      margin-top: 14px;
      display: flex;
      padding: 12px 20px;
      // justify-content: space-between;
      align-items: flex-end;

      // margin-bottom: 31px;
      .title {
        display: flex;
        // align-items: center;
        // margin-bottom: 15px;
        // font-size: 28px;
        // font-weight: 800;
        // color: rgba(40, 40, 40, 1);
        // line-height: 40px;
        // letter-spacing: 1px;
        // cursor: pointer;

        font-size: 15px;
        line-height: 20px;
        padding: 7px 24px;
        border-radius: 17px;
        color: #282828;
        cursor: pointer;
        &-chose {
          font-size: 15px;
          line-height: 20px;
          padding: 7px 24px;
          border-radius: 17px;
          cursor: pointer;
          background-color: #6049ff;
          color: #ffffff;
          -webkit-box-shadow: 0px 2px 8px 0px rgb(111 138 163 / 20%);
          box-shadow: 0px 2px 8px 0px rgb(111 138 163 / 20%);
        }
        p {
          margin: 0;
        }

        .course-header-img {
          width: 62px;
          margin-right: 10px;
        }
      }
      .text {
        font-size: 12px;
        color: rgba(111, 138, 163, 1);
        line-height: 16px;
        margin-left: 10px;
        margin-bottom: 20px;
        display: inline-block;
      }
      .choose {
        display: flex;
        align-items: center;
        background-color: #fff;
        cursor: pointer;
        margin-right: 0;
        margin-left: auto;
        .all {
          font-size: 14px;
          line-height: 19px;
        }
        span.active {
          color: @themeBlue;
          font-size: 14px;
          background: rgba(255, 255, 255, 0.4);
          border-radius: 16px;
          line-height: 20px;
        }
        span:first-child.active {
          font-size: 14px;
          color: @themeBlue;
          background: transparent;
          line-height: 20px;
        }
        .normal {
          font-size: 14px;
          color: #141414;
          line-height: 20px;
        }
        .span {
          margin: 0 11px;
          padding: 6px 12px;
        }
        .choose-img {
          width: 16px;
          height: 16px;
        }
        .more {
          display: flex;
          align-items: center;
          font-size: 13px;
          color: #fff;
          background-color: @themeBlue;
          box-shadow: 0px 4px 5px 0px rgba(90, 83, 245, 0.19);
          padding: 8px 12px;
          border-radius: 16px;
          line-height: 16px;
          letter-spacing: 1px;
          margin-left: 5px;
          margin-right: 0px;
          vertical-align: middle;
          span {
            margin-left: 4px;
          }
        }
      }
      .header-title {
        line-height: 34px;
        font-size: 18px;
        font-weight: bold;
        color: #131313;
      }
    }
    .choose-all {
      background-color: #fff;
      border-radius: 6px;
      padding: 20px;
      margin-bottom: 14px;
      position: relative;
      .all-item {
        line-height: 26px;
        .item-bt {
          min-width: 45px;
          font-size: 14px;
          color: #a5a5a5;
          line-height: 19px;
        }
        .item {
          font-size: 14px;
          padding: 0 16px;
          margin-right: 8px;
          cursor: pointer;
        }
        .i-normal {
          color: #444;
        }
        .i-active {
          border-radius: 14px;
          padding: 3px 15px;
          border: 1px solid @themeBlue;
          color: @themeBlue;
        }
        .i-first {
          color: @themeBlue;
        }
      }
      .select-more {
        display: flex;
        cursor: pointer;
        align-items: flex-end;
        position: absolute;
        bottom: 0;
        right: 0;
      }
      .choose-img {
        width: 16px;
        height: 16px;
      }
      .more {
        vertical-align: middle;
        display: flex;
        min-width: 70px;
        align-items: center;
        font-size: 13px;
        color: #fff;
        background-color: @themeBlue;
        padding: 9px 12px;
        border-radius: 16px;
        line-height: 16px;
        letter-spacing: 1px;
        margin-left: 4px;
        margin-right: 18px;
        margin-bottom: 20px;
        vertical-align: middle;
        span {
          margin-left: 4px;
          flex-shrink: 0;
        }
      }
    }
    .section {
      overflow: hidden;
      margin-top: 20px;
      // margin-bottom: 8px;
      padding: 20px;
      background-color: #fff;
      border-radius: 6px;
      .course-block {
        cursor: pointer;
        display: flex;
        border-radius: 4px;
        background-color: #fff;
        box-shadow: 0px 0px 10px 11px #f3f3f3;
        padding: 20px 16px;
        margin-bottom: 20px;
        position: relative;
        overflow: hidden;
        .course-img {
          width: 120px;
          height: 120px;
          // width: 50%;
          // height: 0;
          border-radius: 6px;
          // padding-bottom: 37.5%;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          // img {
          //   width: 100%;
          // }
        }
        .course-text {
          width: calc(50% - 12px);
          margin-left: 12px;
          position: relative;
          .course-name {
            font-size: 16px;
            font-weight: bold;
            color: #131313;
            line-height: 24px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .course-info {
            position: absolute;
            bottom: 0;
            width: 100%;
            .course-info-text {
              display: flex;
              white-space: nowrap;
              font-size: 14px;
              font-weight: 400;
              color: #666666;
              margin-top: 5px;
              .course-info-title {
                width: 25%;
              }
              .course-info-content {
                width: 75%;
              }
            }
          }
        }
      }
      .section-item:hover {
        box-shadow: 0px 2px 16px 0px rgba(210, 217, 231, 0.17);
      }
      .section-item {
        background: rgba(255, 255, 255, 1);
        border-radius: 8px;
        // float: left;
        width: 30%;

        cursor: pointer;
        position: relative;
        .item-head {
          display: flex;
          align-items: center;
          height: 64px;
          .math {
            background: url("~@/assets/images/pic_subject01@2x.png") no-repeat
              center center;
          }
          .bios {
            background: url("~@/assets/images/pic_subject03@2x.png") no-repeat
              center center;
          }
          .chinese {
            background: url("~@/assets/images/pic_subject02@2x.png") no-repeat
              center center;
          }
          .english {
            background: url("~@/assets/images/pic_subject04@2x.png") no-repeat
              center center;
          }
          .head-l {
            font-size: 14px;
            color: rgba(255, 255, 255, 1);
            line-height: 19px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-size: contain;
          }
        }
        .li {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #6f8aa3;
          line-height: 18px;
          margin-left: 30px;
          .icon-img {
            width: 14px;
            height: 14px;
            margin-right: 8px;
          }
        }
        .class {
          margin-bottom: 13px;
        }
        .right-subject-type {
          position: absolute;
          right: 0;
          bottom: 0;
        }
        .head-name {
          font-size: 19px;
          color: #282828;
          height: 100%;
          // position: relative;
          .head-subject-type {
            width: auto;
            /* position: absolute; */
            /* top: 5px; */
            /* left: 0; */
            height: 20px;
            float: left;
            margin-right: 5px;
            margin-top: 6px;
          }
          p {
            display: -webkit-box;
            height: 60px;
            line-height: 30px;
            margin: 0;
            // text-indent: 43px;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
        }
      }
      .section-item:hover {
        box-shadow: 0px 2px 16px 0px rgba(210, 217, 231, 0.17);
      }
    }

    .no-data {
      margin-bottom: 22px;
      font-size: 22px;
      font-weight: 600;
      color: rgba(100, 108, 149, 0.25);
      line-height: 30px;
      letter-spacing: 2px;
      text-align: center;
      margin: 0 auto;
      .no-img {
        width: 299px;
        margin-bottom: 10px;
      }
    }
    .data-p83 {
      padding-top: 8%;
    }
    .data-p42 {
      padding-top: 10%;
    }
    .no-auth {
      width: 700px;
      margin: 0 auto;
      background-color: #fff;
      .head {
        font-size: 18px;
        color: rgba(100, 108, 149, 1);
        line-height: 24px;
        margin-top: 3px;
        margin-bottom: 25px;
        text-align: center;
      }
      .auth-data {
        margin-bottom: 25px;
      }
      /deep/ .el-tooltip__popper {
        position: absolute;
        border-radius: 4px;
        padding: 10px;
        z-index: 2000;
        font-size: 12px;
        line-height: 1.2;
        min-width: 10px;
        word-wrap: break-word;
        max-width: 500px;

        box-sizing: border-box;
      }
    }
  }
}
// @media screen and (min-width: 1620px) {
//   .show-all-choose {
//     margin-bottom: 28px;
//   }
//   .content {
//     .choose-all {
//       // margin-top: 24px;
//       // margin-bottom: 24px;
//       .all-item {
//         .item-bt {
//         }
//       }
//       .all-item-mb {
//         margin-bottom: 10px;
//       }
//     }
//   }

//   .section-item {
//     // width: 382px;
//     width: 27.286%;
//     margin-bottom: 38px;
//     padding: 26px 32px 34px 22px;
//     .item-head {
//       margin-bottom: 15px;

//       .head-l {
//         width: 32px;
//         height: 32px;
//         margin-right: 12px;
//       }
//     }
//     .li {
//       line-height: 19px !important;
//     }
//   }
//   .section-item:nth-child(3n + 2) {
//     margin: 0 3.28%;
//     // margin: 0 52px;
//   }
// }
// @media screen and (max-width: 1619px) {
//   .show-all-choose {
//     margin-bottom: 28px;
//   }
//   .content {
//     padding-top: 35px !important;
//     .choose-all {
//       // margin-top: 19px;
//       // margin-bottom: 25px;
//       .all-item {
//       }
//     }
//     .all-item-mb {
//       margin-bottom: 10px;
//     }
//   }
//   .section-item {
//     margin-bottom: 26px;
//     width: 334px;
//     padding: 22px 22px 24px 18px;
//     .item-head {
//       margin-bottom: 15px;
//       .head-l {
//         width: 32px;
//         height: 32px;
//         margin-right: 12px;
//       }
//       .head-name {
//         font-size: 17px !important;
//       }
//     }
//   }
//   .section-item:nth-child(3n + 2) {
//     margin: 0 40px;
//   }
// }
.choose-more-term:hover:first-child {
  color: @themeBlue !important;
}
.choose-more-term:hover:not(:first-child) {
  background: rgba(255, 255, 255, 0.4);
  border-radius: 16px;
  color: @themeBlue !important;
}
/deep/ .cell {
  text-align: center;
}
/deep/.el-table th {
  background-color: rgba(245, 246, 247, 1) !important;
}
.pagination {
  background-color: #fff;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 10px;
}
::-webkit-scrollbar {
  display: none !important; /* Chrome Safari */
}
/deep/ .el-pager li {
  height: 30px;
  line-height: 30px;
  width: 30px;
  min-width: 30px;
  border-radius: 50% !important;
  padding: 0 0px;
  margin: 0 5px;
  font-size: 13px;
  // font-family: "SourceHanSansCN-Medium", "SourceHanSansCN";
  font-weight: 400;
  color: rgba(40, 40, 40, 1);
}
/deep/ .el-pager li.active {
  background: @themeBlue!important;
  color: #fff;
  font-size: 13px !important;
  // font-family: "SourceHanSansCN-Medium", "SourceHanSansCN"!important;
}
// /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
//   color: @themeBlue;
// }
// /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
//   color: #fff !important;
// }
// /deep/.el-pagination__total {
//   font-size: 12px;
//   // font-family: "MicrosoftYaHei"!important;
//   color: rgba(40, 40, 40, 1);
// }
// /deep/.el-pagination button {
//   height: 30px !important;
//   line-height: 30px !important;
// }
// /deep/ .el-pagination button:hover {
//   color: @themeBlue !important;
// }
// /deep/ .el-table th.gutter {
//   display: table-cell !important;
// }
// /deep/ .el-table--enable-row-transition .el-table__body td {
//   max-width: 100px;
// }
/deep/.el-pagination.is-background .btn-prev {
  background-color: #fff;
  border-radius: 50%;
}
/deep/ .el-pagination.is-background .el-pager li {
  background-color: #fff;
}
/deep/ .el-pager li {
  background-color: #fff !important;
  border: 1px solid #dcdddd;
}
/deep/ .el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background-color: #fff !important;
  border: 1px solid #dcdddd;
  border-radius: 50%;
}
/deep/.el-pagination__total {
  font-size: 14px !important;
  // font-family: "MicrosoftYaHei"!important;
  color: #999 !important;
}
/deep/.el-pagination.is-background .btn-next,
/deep/.el-pagination.is-background .btn-prev,
/deep/.el-pagination.is-background .el-pager li {
  background-color: #fff !important;
  border: 1px solid #dcdddd;
  border-radius: 50%;
  color: #999;
}
/deep/.el-pager li.active {
  background-color: #6049ff !important;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #6049ff !important;
}
/deep/.el-pagination .btn-prev .el-icon {
  color: #999;
  font-size: 14px;
}
/deep/.el-pagination__total {
  color: #999 !important;
}
/deep/.el-pagination .btn-next .el-icon,
/deep/.el-pagination .btn-prev .el-icon {
  font-size: 14px;
  color: #999;
}
/deep/.el-pagination__total {
  font-size: 14px !important;
  color: #999 !important;
}
.ml20 {
  margin-left: 20px;
}
.mb40 {
  margin-bottom: 40px;
}
</style>
<style lang="less">
.el-tooltip__popper {
  position: absolute;
  border-radius: 4px;
  padding: 10px;
  z-index: 2000;
  font-size: 12px;
  line-height: 1.2;
  min-width: 10px;
  word-wrap: break-word;
  max-width: 500px;

  box-sizing: border-box;
}
</style>
<style lang="less">
.home {
  .guide-dialog {
    position: absolute;
  }
}
</style>
